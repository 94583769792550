@import url('https://fonts.googleapis.com/css2?family=Dosis:wght@400;600;700&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
    .animation-delay-2000 {
        animation-delay: 2s;
    }

    .animation-delay-4000 {
        animation-delay: 4s;
    }
}

@layer base {
    html {
      font-family: "Dosis", system-ui, sans-serif;
    }
  }

  body {
    background: black;
  }